.table {
  border: none;
}

.table td {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.last-seen {
  white-space: nowrap;
  overflow: hidden;
}

table td:first-child {
  border-left: none;
}

table td:last-child {
  border-right: none;
}

table th:first-child {
  border-left: none;
}

table th:last-child {
  border-right: none;
}

table tr:first-child th {
  border-top: #1c2b36;
  padding-top: 35px;
  background-color: #1c2b36;
  border-color: #7a8891;
  color: rgb(233, 233, 233);
}

table tr:last-child td {
  border-bottom: none;
}

.text {
  font-family: 'Montserrat', sans-serif;
  color: #aecbd3;
}

.sectionTitle {
  font-size: 3rem;
  line-height: 3rem;
  color: #aecbd3;
  position: relative;
  padding-left: 40px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: -30px;
}

.sw-diamond {
  height: 60px;
  width: 80px;
  margin-top: 25px;
}