body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  background-color: #29404f;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  margin-right: 2rem;
}

.navbar {
  padding: 1.5rem 1rem;
  background-color: #1c2b36;
  border-color: #29404f;
  justify-content: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 20rem;
}

.nav-link {
  line-height: 60px;
  height: 60px;
  padding: 3px 20px 0px 20px;
  width: auto;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: left;
  text-decoration: none;
  border: 0;
  border-radius: 0;
  font-family: inherit;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  color: #eaeaea !important;
}

.active {
  color: #e01d6c !important;
}

.nav-link:hover {
  color: #e01d6c !important;
}

.navbar-nav {
  padding-right: 320px !important;
}
